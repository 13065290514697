import React from 'react';
import { OverlayTrigger, Popover } from "react-bootstrap";



const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Insufficient USDT</Popover.Header>
      <Popover.Body>You must swap some assets for USDT</Popover.Body>
    </Popover>
  );


export function SubscriptionCard({ tier, usdtAmount, allowance, handleBuySubscription, handleApprove }) {
    const tierMapping = [1, 7, 30, 90, 180, 365];
    const priceMapping = [10, 30, 50, 130, 240, 450];
    return (
      <div className="card text-center card">
        <div className="card-header">{tierMapping[tier]} Days</div>
        <div className="card-body">
          <h5 className="card-title">{priceMapping[tier]} USDT</h5>
          {Number(usdtAmount) / 10 ** 18 > priceMapping[tier] ? (
            <div>
              {allowance && allowance.gt(0) ? (
                <button
                  className="btn btn-primary"
                  onClick={() => handleBuySubscription(tier)}
                >
                  Buy
                </button>
              ) : (
                <button
                  className="btn btn-warning"
                  onClick={() => handleApprove()}
                >
                  Approve USDT
                </button>
              )}
            </div>
          ) : (
            <div>
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={popover}
              >
                <button className="btn btn-danger">Insufficient USDT</button>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </div>
    );
  }