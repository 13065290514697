import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Contract, parseUnits } from "ethers";
import { ethers } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { ERC20_ABI, CONTRACT_ABI } from "./abi";
import { SubscriptionCard } from "./components/card";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';


const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";
const CONTRACT_ADDRESS = "0xB3E20a0C33F230b7840C7c120153D3619324e184";

const MAX_ALLOWANCE =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";



function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [allowance, setAllowance] = useState(0);
  const [subscriptionExpiry, setSubscriptionExpiry] = useState(null);
  const [usdtAmount, setUsdtAmount] = useState(0);
  const [expired, setExpired] = useState(false);
  const [selectedGiftTier, setSelectedGiftTier] = useState("");
  const [selectedGiftAddress, setSelectedGiftAddress] = useState("")

  const [selectedSetTier, setSelectedSetTier] = useState("");
  const [selectedSetDuration, setSelectedSetDuration] = useState("");
  const [selectedSetPrice, setSelectedSetPrice] = useState("");




  const connectToMetaMask = async () => {
    
    const ethereumProvider = new ethers.BrowserProvider(window.ethereum);
    if (!ethereumProvider) {
      console.error("MetaMask is not installed!");
      return;
    }
    setProvider(ethereumProvider);


    const chainId = await ethereumProvider.getNetwork().then(network=>network.chainId)
    if (chainId !== 56n){
      toast.error("Please connect to Binance Smart Chain")
      setProvider(null)
      return
    }

    const accounts = await ethereumProvider.send("eth_requestAccounts", []);
    if (accounts.length === 0) {
      toast.error("No account connected")
      return;
    }
    setConnectedAddress(accounts[0]);


    const signer = await ethereumProvider.getSigner()
    if (signer === null){
      toast.error("No account connected")
      return
    }
    setSigner(signer);
  };

  useEffect(() => {
    async function initialize() {
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);
      const usdtContract = new Contract(USDT_ADDRESS, ERC20_ABI, provider);
      const currentTime = Date.now();
      

      if (provider && signer && connectedAddress) {
        setAllowance(0);
        setUsdtAmount(0);
        setExpired(false);
        setIsOwner(connectedAddress.toLowerCase() === (await contract.owner()).toLowerCase());
        
       
        if (!isOwner) {
          const expiryTime = Number(await contract.userExpiry(connectedAddress));
          setSubscriptionExpiry(expiryTime);
          setExpired(currentTime > (expiryTime * 1000));

          setAllowance(BigNumber.from(await usdtContract.allowance(connectedAddress,CONTRACT_ADDRESS)));
          setUsdtAmount(Number(await usdtContract.balanceOf(connectedAddress,provider)));

          
        }
      }
    }

    initialize();
  }, [provider, signer, connectedAddress]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        setConnectedAddress(accounts[0]);
      });
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners("accountsChanged");
      }
    };
  }, []);

  const handleApprove = async () => {
    if (provider && signer) {
      const usdtContract = new Contract(USDT_ADDRESS, ERC20_ABI, provider);
      const tx = await usdtContract
        .connect(signer)
        .approve(CONTRACT_ADDRESS, MAX_ALLOWANCE);
      await tx.wait();
      toast.success(
        <div>
          Approved successful! 
          <a href={`https://bscscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">
            View on BSCScan
          </a>
        </div>, {
        position:"top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      }
      );
      setAllowance(BigNumber.from(MAX_ALLOWANCE));
    }
  };

  const disconnectWallet = () => {
    setProvider(null);
    setSigner(null);
    setConnectedAddress("");
    setIsOwner(false);
    setAllowance(0);
  };

  const handleBuySubscription = async (tier) => {
    if (provider && signer) {
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      try {
        const tx = await contract.buySubscription(tier);
        const toastId = toast.info("Waiting for transaction to be mined...", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true
        });
        await tx.wait();

        toast.update(toastId, {
          render: (
            <div>
              Subscription purchased successfully!<br></br>  
              <a href={`https://bscscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">
                View on BSCScan
              </a>
            </div>
          ),
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } catch (error) {
        console.error("Error purchasing subscription:", error);
        toast.error("Failed to purchase subscription. Please try again.", {
          position:"top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      }
    }
  }

  const handleGiftSubscription = async () =>{
      
        console.log(selectedGiftAddress)
        console.log(selectedGiftTier)
        const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        try{
          const tx = await contract.giftSubscription(selectedGiftAddress, selectedGiftTier);
          const toastId = toast.info("Waiting for transaction to be mined...", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true
        });
        await tx.wait();
        toast.update(toastId, {
          render: (
            <div>
              Subscription set successfully!<br></br> 
              <a href={`https://bscscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">
                View on BSCScan
              </a>
            </div>
          ),
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } catch (error) {
        console.error("Error gifting subscription:", error);
        toast.error("Failed to gift subscription. Please try again.", {
          position:"top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

      
    }
  }

  const handleSetSubscription = async () =>{
    if (isOwner){
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      try{
        const duration = Number(selectedSetDuration) * 86400
        const price = parseUnits(selectedSetPrice, "ether") //Number(selectedSetPrice) * (10**18)
        const tx = await contract.setSubscription(selectedSetTier, duration, price);
        const toastId = toast.info("Waiting for transaction to be mined...", {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true
      });
      await tx.wait();
      toast.update(toastId, {
        render: (
          <div>
            Subscription set successfully!<br></br> 
            <a href={`https://bscscan.com/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">
              View on BSCScan
            </a>
          </div>
        ),
        type: toast.TYPE.SUCCESS,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } catch (error) {
      console.error("Error setting subscription:", error);
      toast.error("Failed to set subscription. Please try again.", {
        position:"top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

    }
  }
}

  return (
    
    <Container className="mt-5 main">
      <ToastContainer />
        {
        connectedAddress 
        ? 
          <>
              <div className="disconnect-button"><Button className="btn btn-dark" onClick={disconnectWallet}> Disconnect Wallet</Button></div>
              <h6 className="alert alert-success" role="alert">Connected Address:<br></br>{connectedAddress}</h6>
              <h6 className="alert alert-info">Your USDT amount: {(usdtAmount / 10 ** 18).toString()}</h6>
            { 
              expired 
              ? 
              <h6 className="alert alert-danger">No Active Subscription</h6>
              :
              <div className="alert alert-warning">
                <h5>Your subscription expires on: </h5>
                <h6>{new Date(Number(subscriptionExpiry) * 1000).toLocaleString()}</h6>
              </div>
            }
            {isOwner 
            ?
              <>
              <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
              <Accordion.Header>Gift Subscription</Accordion.Header>
              <Accordion.Body>
              <div className="gift-subscription">
              
                <Form.Label> Duration: </Form.Label>
                <Form.Select 
                aria-label="Default select example"
                onChange={(e)=>setSelectedGiftTier(e.target.value)}
                >
                  <option>Select gift duration</option>
                  <option value="0">1 Day</option>
                  <option value="1">7 Days</option>
                  <option value="2">30 Days</option>
                  <option value="3">90 Days</option>
                  <option value="4">180 Days</option>
                  <option value="5">365 Days</option>
                </Form.Select >
                <Form.Group className="mb-3" controlId="formAddress">

                  <Form.Label>Address</Form.Label>

                  <Form.Control 
                  type="text" 
                  placeholder="Address" 
                  onChange={(e)=>setSelectedGiftAddress(e.target.value)}
                  />
                  </Form.Group>
                <Button onClick={handleGiftSubscription} variant="primary" type="submit">
                  Gift Subscription
                </Button>
              </div>
              </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Header>Set New Subscription</Accordion.Header>
                <Accordion.Body>
                <div className="set-subscription">
                <Form.Label>Tier</Form.Label>
                <Form.Select 
                aria-label="Default select example"
                onChange={(e)=>setSelectedSetTier(e.target.value)}
                >
                  <option>Select a free tier</option>
                  <option value="6">Tier 6</option>
                  <option value="7">Tier 7</option>
                  <option value="8">Tier 8</option>
                </Form.Select>
                <Form.Group className="mb-3" controlId="formDuration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder="Insert the duration in days"
                  onChange={(e)=>setSelectedSetDuration(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control 
                  type="text" 
                  placeholder="Insert Price in USDT"
                  onChange={(e)=>setSelectedSetPrice(e.target.value)}
                   />
                </Form.Group>
                
                <Button onClick={handleSetSubscription}variant="primary" type="submit">
                  Set new subscription
                </Button>
              </div>
                </Accordion.Body>
              </Accordion.Item>
              </Accordion>
                
              </>
            :
              <div className="subscription-tiers">
                {[0, 1, 2, 3, 4, 5].map((tier) => (
                  <SubscriptionCard
                  key={tier}
                  tier={tier}
                  usdtAmount={usdtAmount}
                  allowance={allowance}
                  handleBuySubscription={handleBuySubscription}
                  handleApprove={handleApprove}
                  />
                ))}
              </div>
            }
          </>
        :
        <div className="connect-button">
        <Button  onClick={connectToMetaMask}>Connect to MetaMask</Button>
        </div>
        
        }
    </Container>
  );
}

export default App;
