import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from "../assets/logo.png"

function Header() {
    return (
        <Navbar bg="light" expand="lg">
            <Container className="justify-content-center">
                <Navbar.Brand href="#" className="logo">
                    <img src={logo} alt="Logo"/>
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;
