import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import logo from "../assets/logo-MIN.png"

function Footer() {
    return (
        <Navbar className="footer" bg="light" expand="lg">
            <Container className="justify-content-center">
                <Navbar.Brand href="#" className="logo">
                    <img src={logo} alt="Logo"/>
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Footer;
